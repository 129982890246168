import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import PageHeader from "../components/PageHeader"
import { Main, Container } from "../components/Elements"

const PricesPage = () => {
  const { heroImage } = useStaticQuery(graphql`
    query PricesPageQuery {
      heroImage: file(relativePath: { eq: "hero/be-your-best.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, quality: 90)
        }
      }
    }
  `)

  const heroImageData = getImage(heroImage)

  return (
    <Main>
      <Seo
        title="Prices | Greatest value for money on the market."
        desc="Sports Massage & Physiotherapy in the comfort of your own home. Initial assessment from £60. Refer a friend and you both get £5 off"
      />

      <PageHeader
        image={heroImageData}
        title="Prices"
        text="Professional and affordable"
      />

      <Container>
        <div className="pb-20 grid xl:grid-cols-12 gap-10">
          <div className="grid xl:col-span-9 gap-y-20">
            <PriceSection id="sports-massage" name="Sports Massage">
              <h5 className="text-2xl font-header font-semibold">
                Initial assessment
              </h5>
              <PriceItem
                name="90 minutes"
                price="£80"
                sub={[
                  "Includes: Initial assessment, differential diagnosis, advice service, education and sports massage with hands or with tools depending on your preference.",
                  "",
                  "Ideal candidates are those who have known tension or aching from sedentary desk based job or those who engage in repetitive physical activities like running, manual work or parenting. Massage can alleviate tension, increase mobility and reduce pain.  Initial assessments do take approximately 15 minutes so a 90 minute appointment provides us enough time to assess you thoroughly and provide an effective treatment.",
                ]}
              />
              <PriceItem
                name="60 minutes"
                price="£60"
                sub={[
                  "Includes: Initial assessment, differential diagnosis, advice service, education and sports massage with hands or with tools depending on your preference.",
                  "",
                  "Ideal for those who wish to try the service out prior to committing to longer appointments.",
                ]}
              />
              <h5 className="mt-12 text-2xl font-header font-semibold">
                Treatment
              </h5>
              <PriceItem
                name="90 minutes"
                price="£80"
                sub={[
                  "Includes: Either Deep tissue, Sports or relaxing massage, with or without instruments depending on your preference.",
                  "",
                  "Ideal for those who do not have time to head to a clinic or spa but wish to maintain their bodies in the comfort of their own environment, 90 minutes provides enough time to work on the whole body and therefore anyone who wishes to take time to relax or has multiple areas that need work, this is the treatment for you.",
                ]}
              />
              <PriceItem
                name="60 minutes"
                price="£60"
                sub={[
                  "Includes: Either Deep tissue, Sports or relaxing massage, with or without instruments depending on your preference.",
                  "",
                  "60 minutes massage is the most common treatment I provide and is suitable to those who have localised tension or pain where treatment can be focused on with the additional surrounding areas.",
                ]}
              />

              <h5 className="mt-12 text-2xl font-header font-semibold">
                Sports Massage Package Deals
              </h5>
              <p>
                All Sports Massage packages include initial assessment if
                required.
              </p>

              <div className="flex justify-between">
                <div className="w-full leading-7">
                  <ul role="list" className="divide-y divide-gray-100 -mb-5">
                    {[
                      {
                        name: "5 * 60 minute Sports Massage",
                        price: "£270 (Saving £30!)",
                      },
                      {
                        name: "5 * 90 minute Sports Massage",
                        price: "£360 (Saving £40!)",
                      },
                      {
                        name: "10 * 60 minute Sports Massage",
                        price: "£525 (Saving £75!)",
                      },
                      {
                        name: "10 * 90 minute Sports Massage",
                        price: "£700 (Saving £100!)",
                      },
                    ].map(({ name, price }) => (
                      <li className="flex flex-col sm:flex-row justify-between gap-x-6 gap-y-4 py-5">
                        <div className="flex min-w-0 gap-x-4">
                          <div className="min-w-0 flex-auto">
                            <p className="text-xl font-semibold leading-6">
                              {name}
                            </p>
                          </div>
                        </div>
                        <div>
                          {/* <p className="font-header font-bold">{price}</p> */}
                          <p className="text-2xl sm:font-semibold leading-6">
                            {price}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </PriceSection>

            <div className="xl:hidden">
              <Refer />
            </div>
            <hr className="hidden xl:block" />
            <PriceSection
              id="physiotherapy"
              name="Physiotherapy"
              // intro="To gain long term benefit from massage it is recommended to book at least 4 - 6 sessions."
            >
              <h5 className="text-2xl font-header font-semibold">
                Initial assessment
              </h5>
              <PriceItem
                name="90 minutes"
                price="£80"
                sub={[
                  "Includes: Initial assessment, differential diagnosis, gait / mobility analysis, advice service, exercise prescription, exercise demonstration, potential onward referral and massage.",
                  "",
                  "Initial assessment - 60 minutes - £60 - Includes: Initial assessment, differential diagnosis, gait / mobility analysis, advice service, exercise prescription, exercise demonstration and potential onward referral.",
                ]}
              />
              <PriceItem
                name="60 minutes"
                price="£60"
                sub={[
                  "Includes: Initial assessment, differential diagnosis, gait / mobility analysis, advice service, exercise prescription, exercise demonstration and potential onward referral.",
                ]}
              />

              <h5 className="mt-12 text-2xl font-header font-semibold">
                Treatment
              </h5>

              <PriceItem
                name="90 minutes"
                price="£70"
                sub={[
                  "Includes: Injury rehabilitation, exercise technique review, Strength and conditioning, behaviour modification advice, return to sport programs, surgery recovery, education, desk set up review & hands on modalities like massage.",
                ]}
              />
              <PriceItem
                name="60 minutes"
                price="£50"
                sub={[
                  "Includes: Injury rehabilitation, exercise technique review, Strength and conditioning, behaviour modification advice, return to sport programs, surgery recovery, education, desk set up review.",
                ]}
              />
              <PriceItem
                name="30 minutes"
                price="£35"
                sub={[
                  "(Chester-le-Street exclusive)",
                  "Includes: Injury rehabilitation, exercise technique review, behaviour modification advice, return to sport programs, education, desk set up review.",
                ]}
              />

              <h5 className="mt-12 text-2xl font-header font-semibold">
                Physiotherapy Package Deals
              </h5>
              <p>
                All Physiotherapy packages include initial assessment if
                required.
              </p>

              <div className="flex justify-between">
                <div className="w-full leading-7">
                  <ul role="list" className="divide-y divide-gray-100 -mb-5">
                    {[
                      {
                        name: "4 * 60 minute appointments",
                        price: "£180 (Saving £20!)",
                        blurb:
                          "Most simple injuries to your, ankle, knee or elbow can be rehabilitated to a decent standard with this package. At Ascension we will empower your rehabilitation journey by providing you all the skills and knowledge on how to manage and rehabilitate your conditions after your sessions have been completed. Complex injuries and chronic conditions to areas like the spine, shoulder or hip may require additional input depending on the severity of the condition.",
                      },
                      {
                        name: "4 * 90 minute appointments",
                        price: "£252 (Saving £28!)",
                        blurb:
                          "Ideal for athletes, people with chronic conditions and people who want to take a proactive approach to their healthcare. 90 minutes provides enough time for both supervised exercise rehabilitation as well as hands on modalities like sports massage. The combined approach really gets the most out of each appointment and provides you with the knowledge and skills on how to continue your rehabilitation in your own time.",
                      },
                    ].map(({ name, price, blurb }) => (
                      <li className="py-5">
                        <div className="flex flex-col sm:flex-row justify-between gap-x-6 gap-y-4">
                          <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto">
                              <p className="text-xl font-semibold leading-6">
                                {name}
                              </p>
                            </div>
                          </div>
                          <div>
                            {/* <p className="font-header font-bold">{price}</p> */}
                            <p className="text-2xl sm:font-semibold leading-6">
                              {price}
                            </p>
                          </div>
                        </div>
                        <p>{blurb}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </PriceSection>

            <hr />

            <PriceSection name="Special Offers">
              <div className="flex justify-between">
                <div className="w-full leading-7">
                  <span
                    className="block border-b border-black text-3xl font-header font-semibold"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Gift Cards
                  </span>
                  <p>
                    Want to treat someone special for the holidays or a
                    birthday, perhaps a nice way to relax for your anniversary?
                    Or is someone you know in pain and needs relief? A sports
                    massage gift card makes the gift to show you care and they
                    can use to book a time that suits them. (Prices as above)
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-full leading-7">
                  <span
                    className="block border-b border-black text-3xl font-header font-semibold"
                    style={{ fontSize: "1.5rem", marginTop: "1.81rem" }}
                  >
                    Student discount: £5 off
                  </span>
                  <p>(Not applicable to 30 minute massage)</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-full leading-7">
                  <span
                    className="block border-b border-black text-3xl font-header font-semibold"
                    style={{ fontSize: "1.5rem", marginTop: "1.81rem" }}
                  >
                    Blue light card discount: £5 off
                  </span>

                  <p>For emergency and armed service men and women</p>
                  <p>(Not applicable to 30 minute massage)</p>

                  <div className="mt-5 text-center">
                    <img
                      src="/images/blue-light-card.png"
                      style={{ maxWidth: "300px" }}
                      className="img-fluid inline-block"
                      // height="336"
                      // width="597"
                      alt="BLC Logo"
                    />
                  </div>
                </div>
              </div>
            </PriceSection>
          </div>

          <aside className=" hidden xl:block xl:col-span-3">
            <Refer />
          </aside>

          <div className="xl:col-span-12">
            <p>*Only one offer applicable per purchase.</p>
            {/* <p>**Travel charge may be applied dependent upon location.</p> */}
            <p className="mb-3">
              ***Get in touch if you wish to become an affiliated club.
            </p>
            <p>
              Terms and conditions: All package deals can retroactively include
              initial assessment, we will simply discount the amount spent on
              your initial assessment from the care plan and use this as one of
              the appointments included. All care plans are valid for 12 months
              after purchase. Sessions that have not been used after 12 months
              will not be able to be redeemed. Care plans can be split among
              other people so long as they are in the same location and are
              being seen in the same visit.
            </p>
          </div>
        </div>
      </Container>
    </Main>
  )
}

export default PricesPage

const PriceSection = ({ children, id, name, intro }) => (
  <div id={id} className="grid gap-4">
    <h3 className="text-3xl font-header font-bold">{name}</h3>
    <div>
      {intro && <p className="mt-4">{intro}</p>}
      {children}
    </div>
  </div>
)

const PriceItem = ({ name, price, sub }) => (
  <div className="flex justify-between">
    <div className="w-full leading-7">
      <span
        className="block border-b border-black text-3xl font-header font-semibold"
        style={{ fontSize: "1.5rem", marginTop: "1.81rem" }}
      >
        {name}
      </span>
      {sub.map(text => {
        if (!text) {
          return <br />
        }

        return <p>{text}</p>
      })}
    </div>
    <div className="font-header font-bold text-price">{price}</div>
  </div>
)

const Refer = () => (
  <div className="bg-b-blue border border-transparent text-base font-medium rounded-md">
    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-10 sm:px-6 lg:px-8 xl:py-20">
      <h3 className="mt-5 lg:mt-0 text-3xl font-header font-bold text-white col-span-3 md:col-span-1">
        <span className="block">Refer a friend</span>
      </h3>
      <p className="mt-4 font-body leading-6 text-lg text-white">
        They get £5 off and you get £5 off your next massage!
      </p>
      <Link
        to="/contact"
        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradiant-orange hover:bg-b-orange-dark sm:w-auto"
      >
        Get in touch
      </Link>
    </div>
  </div>
)
